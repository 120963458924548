@import '../../base.scss';

.item{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 600px;
    width: 100%;

    &:hover{
        transition: all .3s;
        cursor: pointer;
        filter: contrast(60%);
        
    }
}

.box_image{
    width: 100%;
    aspect-ratio: 2/3;
    border-radius: 6px;
    overflow: hidden;
}

.image{
    width: 100%;
    height: auto;
}