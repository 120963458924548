@import '../../base.scss';

.main{
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    width: 100%;
}

.container{
    @include main-column;
    margin:  0 0 30px;
}

.title{
    @include title;
    margin-bottom: 10px;
}

.text{
    @include text;
    margin-bottom: 30px;
    text-align: center;
}

.list{
    max-width: 1400px;
    width: 100%;
    display: grid;
    box-sizing: border-box;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    padding: 5px;
}

.item{
    min-width: 230px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.image{
    width: 100%;
}

@media(max-width: 1120px){
    .list{
        grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    }
}

@media(max-width: 800px){
    .list{
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }
}

@media(max-width: 425px){
    .list{
        gap: 10px;
    }
}