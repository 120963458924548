@import '../../base.scss';

.main{
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    width: 100%;
}

.container{
    max-width: 1440px;
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    padding: 50px 20px;
    gap: 40px;
}

.box_info{
    max-width: 700px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}


.title{
    @include title;
    text-align: left;
}

.text{
    @include text;
    text-align:justify;
}

.image{
    width: 550px;
    aspect-ratio: 1/1;
    border-radius: 6px;
}

.box_link{
    max-width: 400px;
    width: 100%;
    display: grid;
    box-sizing: border-box;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 20px;
    margin-top: 30px;
}

.item{
    display: flex;
    align-items: center;
    justify-content: center;
}

.link{
    color: $green;
    font-weight: 700;
    text-decoration: none;
    font-size: 26px;
    line-height: 1.2;
    margin-top: 20px;

    &:hover{
        opacity: .7;
        transition: 0.5s;
    }
}

@media(max-width: 1100px){
    .image{
        width: 45%;
    }
}


@media(max-width: 768px){

    .container{
        display: flex;
        flex-direction: column-reverse;
        align-items: center;   
        padding: 40px 10px;
    }

    .image{
        width: 100%;
    }
    
    .box_info{
        display: flex;
        flex-direction: column;
        align-items: center;  
    }

    .link{
        font-size: 22px;
    }

    .box_link{
        margin-top: 10px;
    }

}

@media(max-width: 425px){

    .container{
    padding: 30px 10px;
    }

    .box_link{
        max-width: 300px;
        grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    }
}