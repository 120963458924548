@import '../../base.scss';

.main{
    @include main-column;
}

.box{
    @include box-column;
}

.container{
    @include box-column;
}

.title{
    @include title
}

.list{
    box-sizing: border-box;
    width: 100%;
    margin-top: 20px;
    padding: 0 20px;
}

@media(max-width: 768px){
    .list{
        padding: 0 10px;
    }
}

