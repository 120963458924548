@import '../../base.scss';

.main{
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    width: 100%;
}

.container{
    @include main-column;
    margin:  0 0 30px;
}

.title{
    @include title;
    margin-bottom: 30px;
}

.list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}


.item {
    display: flex;
    align-items: flex-start;
    gap: 30px;
    margin-bottom: 20px;
}

.check {
    color: $element;
    font-size: 28px;
    font-weight: 600;
    margin: 0;
}

.text {
    font-size: 22px;
    line-height: 1.2;
    color: $title;
    margin: 0;

    & span {
        font-weight: 700;
    }
}

@media(max-width: 768px){

    .item {
        gap: 10px;
    }

    .check {
        color: $element;
        font-size: 20px;
        font-weight: 600;
        margin: 0;
    }
    
    .text {
        font-size: 16px;
        line-height: 1.2;
        color: $title;
        margin: 0;
    }
}