@import '../../base.scss';

.main{
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    width: 100%;
}

.container{
    @include main-column;
    padding-bottom: 30px
}

.title{
    @include title;
    margin-bottom: 30px;
}

.list{
    max-width: 1200px;
    width: 100%;
    display: grid;
    box-sizing: border-box;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    gap: 30px;
}

.item{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

.subtitle{
    max-width: 100%;
    width: 100%;
    height: 75px;
    text-align: center;
    text-transform: uppercase;
    color: $white;
    font-weight: 700;
    font-size: 18px;
    line-height: 1.2;
    margin:  0;
    background-color: $element;
    border-radius: 6px;
    padding: 15px 5px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
}

.box{
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 10px;
}

.item_box{
    max-width: 100%;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 20px;
    margin-top: 30px;
    padding: 0 10px;
}

.image{
    width: 25px;
    height: auto;
    margin-right: 10px;
}

.text{
    @include text;
    margin: 0;
}

.price{
    max-width: 100px;
    width: 100%;
    text-align: center;
    font-weight: 700;
    font-size: 18px;
    line-height: 1.2;
    margin:  0;
}

@media(max-width: 1020px){

    .list{
        grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
        gap: 20px;
    }

    .subtitle{
        font-size: 16px;
    }

    .price{
        font-size: 16px;
    }
}

@media(max-width: 810px){
    .list{
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        gap: 20px;
    }
}

@media(max-width: 768px){

    .list{
        grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    }
}

@media(max-width: 425px){

    .list{
        grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    }
}