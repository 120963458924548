@import '../../base.scss';

.main{
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    width: 100%;
}

.box{
    @include main-column;
    padding-bottom: 50px
}

.title{
    @include title;
    margin-bottom: 30px;
}

.list{
    max-width: 1200px;
    width: 100%;
    display: grid;
    box-sizing: border-box;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
}

.item{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    box-shadow: 0 0 6px rgba(41, 46, 50, 0.6);
    border-radius: 8px;
    padding: 20px 30px;
    height: 120px;
    overflow: hidden;
}

.item_image{
    max-width: 100%;;
    object-fit: cover;
}

///////////
.list_text {
    max-width: 1200px;
    width: 100%;
    display: grid;
    box-sizing: border-box;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    gap: 15px;
    padding: 0 20px;
}

.item_box {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.item_circle {
    box-sizing: border-box;
    min-width: 10px;
    height: 10px;
    margin-right: 20px;
    background-color: $element;
    border-radius: 50%;
}

.item_text {
    display: flex;
    align-items: center;

}

@media(max-width: 768px){
///////////
.list_text {
    grid-template-rows: 1fr;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
}

}

@media(max-width: 500px){
    ///////////
    .list_text {
        padding: 0 30px;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }
    
    }

@media(max-width: 425px){
    .list{
        gap: 20px;
    }

}