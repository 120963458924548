@import '../../base.scss';

@keyframes pulse-1 {
    0%{
        transform: scale( .9);
        opacity: .9;
    }
    100%{
        transform: scale(1.2);
        opacity: .3;
    }
}

@keyframes pulse-2 {
    0%{
        transform: scale( .9);
        opacity: .9;
    }
    100%{
        transform: scale(1.4);
        opacity: .3;
    }
}

.main{
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    width: 100%;
}

.container{
    @include main-column;
    margin:  0 0 30px;
    max-width: 1200px;
    width: 100%;
}

.title{
    @include title;
    margin-bottom: 20px;
}

.item {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    width: 100%;
    border-radius: 16px;
    overflow: hidden;
}

.video {
    width: 100%;
    aspect-ratio: 16/9;
}

.play {
    border-radius: 50%;
    background: $element;
    width: 60px;
    height: 60px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    z-index: 5;

    &::before{
        box-sizing: border-box;
        position: absolute;
        content: '';
        width: 30px;
        height: 30px;
        margin-left: 10px;
        background-image: url(../../image/VideoСollection/play.svg);
        z-index: 15;
    }
}

.pulse{
    background: $element;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: auto;
    width: auto;
    border-radius: 50%;
    animation: pulse-1 1s infinite;
    z-index: 4;
}

.pulse_two{
    background: $element;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: auto;
    width: auto;
    border-radius: 50%;
    animation: pulse-2 1s infinite;
    z-index: 4;
}